import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Components/login';
import Register from './Components/register';
import Dashboard from './Components/Pages/dashboard';
import Requests from './Components/Pages/requests';
import Verification from './Components/verification';
import PrivateRoute from './Components/private';
import Forgot from './Components/Password/forgot';
import Reset from './Components/Password/reset';
import PreviousRequests from './Components/Pages/previousRequests';
import Subscriptions from './Components/Pages/subscription';
import Success from './Components/Pages/success';
import Credits from './Components/Pages/credits';
import Profile from './Components/Pages/profile';
import Error from './Components/404';
import Start from './Components/Pages/start';
import Faqs from './Components/Pages/faq';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route exact path="/" component={Start} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/verification" component={Verification} />
        <Route exact path="/forgot-password" component={Forgot} />
        <Route exact path="/reset-password" component={Reset} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/requests" component={Requests} />
        <Route exact path="/subscriptions" component={Subscriptions} />
        <Route exact path="/request-details/:id" component={PreviousRequests} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/credits" component={Credits} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/faqs" component={Faqs} />
        <Route component={Error} />
      </Switch>
    </div>
  );
}

export default App;
