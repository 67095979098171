import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import './sidebar.css';
import Cookies from 'js-cookie';
import axios from 'axios';

export default function App() {
  const [showShow, setShowShow] = useState(false);
  const [status,setStatus]=useState([]);
  const [data,setData]=useState([]);
  const [credits,setCredits]=useState([]);
  const [timing,setTiming]=useState([]);

  useEffect(()=>{
    getsupport();
    subscribe();
    credit();
    getTiming();
  },[])

  const toggleShow = () => setShowShow(!showShow);

  const getsupport = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getStatuses`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data.data; 
      setStatus(responseData);
    } catch (error) {
      console.error('Error:', error.message);
    } 
  };

  const getTiming = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getTiming`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
  
      const responseData = response.data.data;
      setTiming(responseData);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  async function subscribe() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/subCredits?userId=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      setData(data.data);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  async function credit() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/getCredits?userId=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      setCredits(data.data);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  return (
    <>
      <MDBCollapse show={showShow} tag="nav" className="d-lg-block sidebar" style={{backgroundColor:"#2d353c",overflowY: "auto", maxHeight: "100%"}}>
        <div className="position-sticky">
          <MDBListGroup flush className="mx-3 mt-4">
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"15px"}}>General</p>
            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/dashboard' className='border-0' style={{backgroundColor:"#2d353c",color:"white",fontSize:"15px"}}>
                <MDBIcon fas icon="home me-3" style={{color:"white"}}/>
                Upload File
              </MDBListGroupItem>
            </MDBRipple>

            <MDBRipple rippleTag='span'>
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"15px"}}>Requests</p>
              <MDBListGroupItem tag='a' href='/requests' className='border-0' aria-current='true' style={{backgroundColor:"#2d353c",color:"white",fontSize:"15px"}}>
              <MDBIcon fas icon="list-alt me-3" style={{color:"white"}}/>
                Requests
              </MDBListGroupItem>
            </MDBRipple>

            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"15px"}}>Subscriptions</p>
            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/subscriptions' className='border-0' aria-current='true' style={{backgroundColor:"#2d353c",color:"white",fontSize:"15px"}}>
              <MDBIcon fas icon="newspaper" className="me-3" style={{ color: "white" }} />
                Subscription
              </MDBListGroupItem>
            </MDBRipple>
            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/credits' className='border-0' aria-current='true' style={{backgroundColor:"#2d353c",color:"white",fontSize:"15px"}}>
              <MDBIcon fas icon="coins" className="me-3" style={{ color: "white" }} />
                Credits
              </MDBListGroupItem>
            </MDBRipple>
            <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"15px"}}>Others</p>
            <MDBRipple rippleTag='span'>
            <MDBRipple rippleTag='span'>
              <MDBListGroupItem tag='a' href='/profile' className='border-0' aria-current='true' style={{backgroundColor:"#2d353c",width:"220px",color:"white",fontSize:"15px"}}>
              <MDBIcon fas icon="user" className="me-3" style={{ color: "white" }} />
                Profile
              </MDBListGroupItem>
            </MDBRipple>
            <MDBListGroupItem tag='a' className='border-0' aria-current='true' style={{backgroundColor:"#2d353c",color:"white",fontSize:"15px",cursor:"pointer"}} onClick={()=>{
                if(window.confirm("Are you sure you want to Logout?")){
                  Cookies.remove("email");
                  Cookies.remove("token");
                  Cookies.remove("userId");
                  Cookies.remove("name");
                  window.location.href="/";
                }
              }}>
              <MDBIcon icon="sign-out-alt" className="me-3" style={{ color: "white" }}/>
                Logout
              </MDBListGroupItem>
            </MDBRipple>
            <MDBRipple rippleTag='span'>
            {/* <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"15px"}}>Open Hours</p> */}
            {/* <MDBListGroupItem className='border-0' aria-current='true' style={{backgroundColor:"#d8d8d8",color:"white",fontSize:"15px"}}>
              {timing.map((item,index)=>(
                <div>
                  <span>{item.day}</span>
                  <br />
                  <span>{item.toTime}-{item.fromTime}</span>
                </div>    
              ))}
            </MDBListGroupItem> */}
            {/* <p style={{marginLeft:"23px",marginBottom:"-2px",color:'#687987',fontSize:"15px"}}>Support Status</p>
              <MDBListGroupItem className='border-0' aria-current='true' style={{backgroundColor:"#d8d8d8",color:"white",fontSize:"15px"}}>
                  <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                  {status.map((item, index) => (
                    <MDBCol>
                      <img src={`${process.env.REACT_APP_BASE_URL}/files/${item.image}`} alt={item.name} style={{ width: "30px", borderRadius: "50%", height: "30px", marginRight: "10px" }} />
                      <span style={{marginLeft:"-2px",color:item.Active=="1"?"green":"red"}}>{item.name}</span>
                    </MDBCol>
                  ))}
                  </MDBRow>
              </MDBListGroupItem> */}
            </MDBRipple>
          </MDBListGroup>
        </div>
      </MDBCollapse>

      <MDBNavbar expand='lg' style={{backgroundColor: "#2d353c",position:"relative"}}>
        <MDBContainer fluid>
          <MDBNavbarNav className="d-flex flex-row align-items-center w-auto">
            <MDBNavbarToggler
              type='button'
              aria-label='Toggle navigation'
              onClick={toggleShow}
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>
            <MDBNavbarBrand href='#'>
              <h4 style={{color:"#f9f9f7",fontFamily:"cursive",fontWeight:"bolder"}}><span style={{color:"#a7140b",marginRight:"6px"}}>Art-Tuning</span>Server</h4>
            </MDBNavbarBrand>


          </MDBNavbarNav>
          <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">
            <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
              <MDBDropdown>
              <MDBDropdownToggle tag="a" href="#!" className="hidden-arrow nav-link" style={{ color: "white", fontSize: "12px" }}>
                {data.map((item, index) => (
                  <span key={index} style={{marginRight:"10px"}}>
                    Subscription Expiry: {new Date(new Date(item.dated).setFullYear(new Date(item.dated).getFullYear() + 1)).toLocaleString()}
                  </span>
                ))}
                {credits.map((item,index)=>(
                  <span key={index} style={{marginRight:"10px"}}>
                    Credits: {item.credits}
                  </span>
                ))}
              </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                  
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                   
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                  
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
}