import React, {useEffect, useState} from 'react';
import Sidebar from '../parts/sidebar'
import { MDBSpinner, MDBTable, MDBTableBody } from 'mdb-react-ui-kit';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';

const Credits = () => {
  const [data,setData]=useState([]);
  const [credits,setCredits]=useState([]);

  useEffect(()=>{
    document.body.style.backgroundColor="#1a2229";
    subscribe();
    credit();
  },[])


  async function subscribe() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/subCredits?userId=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      setData(data.data);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  async function credit() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/getCredits?userId=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      setCredits(data.data);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />

      <div className='main-content'>
        <div style={{marginTop:"2%",marginLeft:"3%"}}>
        <center>
        <div>
        <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{margin:"30px"}}>
            <MDBCol>
                <MDBCard className='h-100' style={{backgroundColor:'#2d353c',borderRadius:"0"}}>
                <MDBCardBody style={{color:"white"}}>
                    <MDBCardTitle>Subscription Status</MDBCardTitle>
                    <MDBCardText>
                    {data.length === 0 ? (
                      <p>You don't have an annual subscription</p>
                    ) : (
                      data.map((item, index) => (
                        <div key={index}>
                          <p>You have bought an Annual Subscription</p>
                          <p>Started Date: {new Date(item.dated).toLocaleString()}</p>
                          <p>Expiry: 1 year</p>
                        </div>
                      ))
                    )}
                    </MDBCardText>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol>
                <MDBCard className='h-100' style={{backgroundColor:"#2d353c",borderRadius:"0"}}>
                <MDBCardBody style={{color:"white"}}>
                    <MDBCardTitle>Credits</MDBCardTitle>
                    <MDBCardText>
                      {credits.map((item,index)=>(
                        <p>Currently You have {item.credits} Credits</p>
                      ))}
                    </MDBCardText>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
            </MDBRow>
            </div>
        </center>
        </div>
      </div>
    </div>
  );
};

export default Credits;
