import React, {useEffect, useState} from 'react';
import Sidebar from '../parts/sidebar'
import { MDBTable, MDBTableBody } from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';

const Requests = () => {
  const [requests,setRequests]=useState([]);

  useEffect(()=>{
    document.body.style.backgroundColor="#1a2229";
    getRequests();
  },[])

  async function getRequests() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/myrequests?id=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      setRequests(data.data);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />

      <div className='main-content'>
        <div style={{marginTop:"2%",marginLeft:"3%"}}>
            <h3 style={{color:"white",fontFamily:"bahnschrift"}}>Your requests</h3>
            <div style={{margin:"20px"}}>
            <MDBTable>
                <thead style={{color:'white'}}>
                    <tr>
                    <th>Request#</th>
                    <th>Make</th>
                    <th>Controller</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Details</th>
                    </tr>
                </thead>
                <MDBTableBody>
                    {requests.map((item,index)=>(
                        <tr>
                        <th scope='row'>{item.Id}</th>
                        <th scope='row'>{item.make}</th>
                        <td>{item.ecu}</td>
                        <td>{new Date(item.dated).toLocaleString()}</td>
                        <td style={{ display: "flex" }}>
                          {item.solution=="Not Possible"?(
                            <span style={{ backgroundColor: "red", padding: "5px", borderRadius: "3px", color: 'white' }}>Not Possible</span>
                          ):item.status=="Open"?(
                                <span style={{ backgroundColor: "green", padding: "5px", borderRadius: "3px", color: 'white' }}>Open</span>
                                ):(
                                <span style={{ backgroundColor: "red", padding: "5px", borderRadius: "3px", color: 'white' }}>Closed</span>
                            )}
                        </td>
                        <td><Link to={`/request-details/${item.Id}`}>Details</Link></td>
                        </tr>
                    ))}
                </MDBTableBody>
                </MDBTable>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Requests;
