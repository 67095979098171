import React, {useEffect} from 'react';
import Navbar from './navbar';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import Footer from './footer';

export default function Start() {
  useEffect(()=>{
    document.body.style.backgroundColor="#1a2229";
  },[])
  return (
    <div>
      <Navbar/>
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <img src="../Assets/car4.jpg" alt="" style={{ width: '100%' }} />
        <div style={{ position: 'absolute', top: "45%", left: '50%', transform: 'translate(-50%, -50%)'}}>
          <h1 style={{ color: 'white', fontWeight: 'bold' }}>Fine-Tune Your Performance</h1>
          <h5 style={{color:"black"}}>Unleash the Power of Our Server Optimization Solutions</h5>
        </div>
      </div>

      <div className='work' style={{marginTop:"40px"}}>
        <h2 style={{textAlign:"center",color:"white"}}>How we work?</h2>
      </div>

      <MDBRow className='row-cols-1 row-cols-md-4 g-4' style={{margin:"30px"}}>
      <MDBCol>
        <MDBCard className='h-100' style={{backgroundColor:"#2d353c"}}>
          <MDBCardBody>
            <MDBCardTitle>
              <center>
                <img src="../Assets/register.png" alt="" style={{width:"70px",height:"70px"}}/>
                <h4 style={{color:"white",marginTop:"15px"}}>Register Now</h4>
              </center>
            </MDBCardTitle>
            <MDBCardText style={{color:"#cdcdcd",textAlign:"center"}}>
            Register now free of charge and we will take care of your ordered ECU tuning files immediately.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100' style={{backgroundColor:"#2d353c"}}>
          <MDBCardBody>
            <MDBCardTitle>
              <center>
                <img src="../Assets/credit.png" alt="" style={{width:"70px",height:"70px"}}/>
                <h4 style={{color:"white",marginTop:"15px"}}>Buy Credits</h4>
              </center>
            </MDBCardTitle>
            <MDBCardText style={{color:"#cdcdcd",textAlign:"center"}}>
            Order your performance-enhancing chip tuning files quickly, safely and easily directly online.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100' style={{backgroundColor:"#2d353c"}}>
          <MDBCardBody>
            <MDBCardTitle>
              <center>
                <img src="../Assets/download.png" alt="" style={{width:"70px",height:"70px"}}/>
                <h4 style={{color:"white",marginTop:"15px"}}>Original Upload</h4>
              </center>
            </MDBCardTitle>
            <MDBCardText style={{color:"#cdcdcd",textAlign:"center"}}>
              Payment and processing is uncomplicated and fast via PayPal or credit card.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100' style={{backgroundColor:"#2d353c"}}>
          <MDBCardBody>
            <MDBCardTitle>
              <center>
                <img src="../Assets/down.png" alt="" style={{width:"70px",height:"70px"}}/>
                <h4 style={{color:"white",marginTop:"15px"}}>Tuningfile Download</h4>
              </center>
            </MDBCardTitle>
            <MDBCardText style={{color:"#cdcdcd",textAlign:"center"}}>
            Depending on the work involved, our tuning file service can take between 10 and 60 minutes.
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>

    <MDBRow style={{margin:"30px",marginTop:"5%"}}>
      <MDBCol md={6}>
        <h1 style={{color:"white"}}>Reliable File Service</h1>
        <p style={{textAlign:"justify",color:"#cdcdcd",fontSize:"18px"}}>Art Tuner is a cutting-edge server optimization tool meticulously engineered to enhance performance and streamline operations for businesses of all scales. With its intuitive interface and advanced algorithms, Art Tuner revolutionizes server management, offering unparalleled efficiency and reliability. Leveraging a combination of machine learning and predictive analytics, it intelligently adjusts server configurations in real-time to match workload demands, ensuring optimal resource allocation and minimizing downtime. Art Tuner's adaptive capabilities not only boost productivity but also optimize costs by maximizing hardware utilization. Whether it's fine-tuning memory allocation, optimizing CPU usage, or managing storage resources, Art Tuner empowers businesses to unlock the full potential of their servers, delivering seamless performance and unparalleled scalability.</p>
        <Link to="/login"><MDBBtn style={{backgroundColor:"#9c190f"}}>Get Started</MDBBtn></Link>
      </MDBCol>
      <MDBCol md={6}>
        <center>
          <img src="../Assets/unix.jpg" alt="" />
        </center>
      </MDBCol>
    </MDBRow>

    <MDBRow style={{margin:"20px",marginTop:"5%"}}>
    <MDBCol md={6}>
        <center>
          <img src="../Assets/why.jpg" alt="" />
        </center>
      </MDBCol>
      <MDBCol md={6}>
        <h1 style={{color:"white"}}>Why Choose Us?</h1>
        <p style={{textAlign:"justify",color:"#cdcdcd",fontSize:"18px",margin:"10px"}}>Choosing Art Tuner means selecting a sophisticated server optimization solution that stands out for several compelling reasons. Firstly, our platform is powered by state-of-the-art machine learning and predictive analytics, ensuring that your server resources are dynamically adjusted in real-time to meet evolving workload demands. This adaptability leads to enhanced performance, minimized downtime, and optimized costs. Secondly, Art Tuner offers a user-friendly interface, making server management intuitive and accessible even to non-technical users. Our tool simplifies complex optimization processes, allowing businesses to focus on their core objectives without the hassle of manual configuration. Additionally, our commitment to reliability and security means you can trust Art Tuner to safeguard your data and operations. With our comprehensive support and ongoing updates, you'll always have access to the latest advancements in server optimization technology. Overall, choosing Art Tuner means partnering with a trusted and innovative solution that maximizes the efficiency and effectiveness of your server infrastructure.</p>
      </MDBCol>
    </MDBRow>

    <Footer/>
    </div>
  )
}
