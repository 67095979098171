import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../parts/sidebar';
import { MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import io from 'socket.io-client';

const socket = io('http://localhost:4001');

const PreviousRequests = () => {
  const { id } = useParams();
  const [request, setRequest] = useState([]);
  const [messages, setMessages] = useState([]);
  const messagesContainerRef = useRef(null);
  const shouldAutoScrollRef = useRef(true);
  const [file, setFile] = useState(null);

  useEffect(() => {
    getRequests();
    getMessages();

    const intervalId = setInterval(() => {
      getMessages();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    socket.on('newMessage', getMessages);

    return () => {
      console.log('Cleaning up listener for messages');
      socket.off('newMessage', getMessages);
    };
  }, [socket]);

  useEffect(() => {
    document.body.style.backgroundColor="#1a2229";
    const container = messagesContainerRef.current;

    const handleScroll = () => {
      const isAtBottom = container.scrollTop + container.clientHeight === container.scrollHeight;
      shouldAutoScrollRef.current = isAtBottom;
    };

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = messagesContainerRef.current;

    if (shouldAutoScrollRef.current) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  async function getRequests() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Dealer/myrequests?id=${Cookies.get('userId')}`,
        {
          method: 'GET',
          headers: {
            'api-key': process.env.REACT_APP_API_KEY,
          },
        }
      );

      const data = await response.json();
      setRequest(data.data.filter((item) => item.Id == id));
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function getMessages() {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Dealer/getMessages`, {
        method: 'GET',
        headers: {
          'api-key': process.env.REACT_APP_API_KEY,
        },
      });

      const data = await response.json();
      setMessages(data.data.filter((item) => (item.senderId == Cookies.get("userId") || item.senderId >= 1) && item.ticketNo == id));
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = document.getElementById('message').value;
    if (message !== '' || file) {
      const formData = new FormData();
      formData.append('message', message);
      formData.append('ticketNo', id);
      formData.append('file', file);
      formData.append('name', Cookies.get("name"));
      formData.append('recieverId', 1);
      formData.append('senderId', Cookies.get('userId'));

      try {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/Dealer/chat`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'api-key': process.env.REACT_APP_API_KEY,
          },
        });

        if (socket) {
          socket.emit('message', formData);
        }

        setFile(null);
        getMessages();
        document.getElementById('message').value = "";
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Sidebar />

      <div className="main-content">
        <div style={{ marginTop: '2%', marginLeft: '3%' }}>
          <div style={{ display: 'flex' }}>
            <h3 style={{ color: 'white', fontFamily: 'bahnschrift' }}>Request # {id} </h3>
            {request.map((item, index) => (
              item.status === 'Open' ? (
                <div key={index} style={{ backgroundColor: '#389716', padding: '5px', borderRadius: '5px', color: 'white', height: '35px', marginLeft: '15px' }}>
                  Opened
                </div>
              ) : (
                <div key={index} style={{ backgroundColor: 'red', padding: '5px', borderRadius: '5px', color: 'white', height: '35px', marginLeft: '15px' }}>
                  Closed
                </div>
              )
            ))}
          </div>
          {request.map((item, index) => (
            <div style={{ margin: '20px', marginTop: '-5px' }} key={index}>
              <p style={{ color: 'white', fontWeight: 'bold' }}>Make:</p>
              <p style={{ color: 'white', marginTop: '-15px' }}>{item.make}</p>
              <p style={{ color: 'white', fontWeight: 'bold' }}>ECU / TCU</p>
              <p style={{ color: 'white', marginTop: '-10px' }}>{item.ecu}</p>
              <p style={{ color: 'white', fontWeight: 'bold' }}>Your Comment</p>
              <p style={{ color: 'white', marginTop: '-10px' }}>{item.comments}</p>
            </div>
          ))}

          <div className="conversation" style={{ marginLeft: '8px' }}>
            <h3 style={{ color: 'white', fontWeight: 'bold' }}>Your Conversation</h3>

            <MDBRow style={{ margin: '5px' }}>
              <MDBCol md={6}>
                <div style={{ backgroundColor: '#2d353c', padding: '10px', color: '#2d353c', borderRadius: '7px', height: '250px', overflowY: 'auto' }} ref={messagesContainerRef}>
                  {messages.map((message, index) => (
                    <div key={index} style={{ backgroundColor: '#2d353c', padding: '10px', color: 'white', borderRadius: '7px', marginTop: '-10px' }}>
                      <p style={{ fontWeight: 'bold' }}>{message.senderId == Cookies.get('userId') ? 'ME' : message.firstname}</p>
                      <p style={{ marginTop: '-10px' }}>{message.message}</p>
                      {message.file && (
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '-20px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '5px' }}>
                            <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
                            <line x1="16" y1="8" x2="16" y2="16" />
                            <line x1="8" y1="16" x2="16" y2="16" />
                          </svg>
                          <p style={{ marginLeft: '5px', marginTop: "15px" }}><a href={`${process.env.REACT_APP_BASE_URL}/files/${message.file}`}>{message.file}</a></p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <form onSubmit={handleSubmit}>
                  <MDBInput label="Your Message" id="message" type="text" style={{ backgroundColor: '#2d353c', marginTop: '7px', color: 'white' }} />
                  <MDBInput id="filen" type="file" style={{ backgroundColor: '#2d353c', marginTop: '7px', color: '#d8d8d8' }} onChange={(event) => setFile(event.target.files[0])} />
                  <MDBRow style={{ marginTop: '10px' }}>
                    <MDBCol md={6}>
                      <MDBBtn style={{ backgroundColor: 'white', color:"black" , width: '100%', marginTop: '5px' }} type="submit">Send Message</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </form>
              </MDBCol>
            </MDBRow>

            {request.map((item, index) => (
              item.status !== 'Open' ? (
                <div key={index}>
                  <h3 style={{ color: 'white', fontFamily: 'bahnschrift', marginTop: '10px' }}>Your Solution</h3>
                  <MDBRow style={{ margin: '5px' }}>
                    <MDBCol md={3}>
                      {item.solution=="Not Possible"?(
                        "Solution Not Possible"
                      ):(
                        <a href={`${process.env.REACT_APP_BASE_URL}/files/${item.solution}`}><MDBBtn style={{ backgroundColor: '#303030', border: '1px solid #444748', width: '100%', marginTop: '5px' }}>Download File</MDBBtn></a>
                      )}
                    </MDBCol>
                  </MDBRow>
                </div>
              ) : null
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousRequests;
