import React, {useEffect, useState} from 'react';
import Sidebar from '../parts/sidebar'
import { MDBInput,MDBRow,MDBCol,MDBBtn,MDBSpinner } from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Profile = () => {
  const [countries,setCountries]=useState([]);
  const [firstname,setFirstname]=useState("");
  const [lastname,setLastname]=useState("");
  const [email,setEmail]=useState("");
  const [phone,setPhone]=useState("");
  const [city,setCity]=useState("");
  const [address,setAddress]=useState("");
  const [post,setPost]=useState("");
  const [credits,setCredits]=useState("");
  const [selectedCountry,setSelectedCountry]=useState("");
  const [submit,setSubmit]=useState(false);

  useEffect(()=>{
    document.body.style.backgroundColor="#1a2229";
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryList = response.data.map(country => ({
          name: country.name.common,
          code: country.cca2,
        }));
        setCountries(countryList);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
    getRequests();
  },[])

  async function getRequests() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/profile?id=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      setFirstname(data.data[0].firstname);
      setLastname(data.data[0].lastname);
      setEmail(data.data[0].email);
      setPhone(data.data[0].phone);
      setSelectedCountry(data.data[0].location);
      setCity(data.data[0].city);
      setAddress(data.data[0].address);
      setPost(data.data[0].postcode);
      setCredits(data.data[0].credits);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('location', selectedCountry);
    formData.append('postcode', post);
    formData.append('city', city);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('credits', credits);

    try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Admin/updateUser`, formData, {
        headers: {
        'Content-Type': 'application/json',
        "api-key": process.env.REACT_APP_API_KEY,
        },
    });
    const data = response.data;
    if(data.message=="updated"){
        setSubmit(false);
        showSuccessMessage();
    }
    } catch (error) {
    console.error('Error:', error.message);
    setSubmit(false);
    }
  };

  const showSuccessMessage = () => {
    toast.success('Your profile has been updated', {
      position: 'top-center',
    });
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
       <ToastContainer />
       <Sidebar />

      <div className='main-content'>
        <div style={{marginTop:"2%",marginLeft:"3%"}}>
            <h3 style={{color:"white",fontFamily:"bahnschrift"}}>Your Profile</h3>
            <div style={{margin:"20px"}}>
                <center>
                    <MDBRow className="justify-content-center">
                        <MDBCol md={8}>
                            <form onSubmit={handleSubmit}>
                                <MDBInput id='fname' value={firstname} type='text' style={{ color: "white" }} onChange={(e)=>{setFirstname(e.target.value)}}/>
                                <MDBInput label='Last Name' id='lname' value={lastname} onChange={(e)=>{setLastname(e.target.value)}} type='text' style={{color:"white",marginTop:"8px"}}/>
                                <MDBInput label='Email' id='email' type='text' value={email} style={{color:"white",marginTop:"8px"}} onChange={(e)=>{setEmail(e.target.value)}}/>
                                <MDBInput label='Phone' id='phone' type='text' value={phone} style={{color:"white",marginTop:"8px"}} onChange={(e)=>{setPhone(e.target.value)}}/>
                                <select
                                    className="form-control"
                                    style={{ marginTop: '5px',color:"white",backgroundColor:"#1a2229" }}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    name='country'
                                    required
                                    >
                                    <option value="">Select Country</option>
                                    {countries.map(country => (
                                    <option key={country.code} value={country.code}>
                                        {country.name}
                                    </option>
                                    ))}
                                </select>
                                <MDBInput label='City' id='city' type='text' value={city} style={{color:"white",marginTop:"8px"}} onChange={(e)=>{setCity(e.target.value)}}/>
                                <MDBInput label='Address' id='address' type='text' value={address} style={{color:"white",marginTop:"8px"}} onChange={(e)=>{setAddress(e.target.value)}}/>
                                <MDBInput label='Post Code' id='postcode' type='text' value={post} style={{color:"white",marginTop:"8px"}} onChange={(e)=>{setPost(e.target.value)}}/>
                                <MDBInput label='Credits' id='credits' type='text' value={credits} style={{color:"white",marginTop:"8px"}}/>
                                <MDBBtn style={{marginTop:"10px",width:"100%",backgroundColor:"white",color:"black"}}>
                                    {submit?(
                                        <MDBSpinner></MDBSpinner>
                                    ):(
                                        <span>Update Profile</span>
                                    )}
                                </MDBBtn>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </center>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
