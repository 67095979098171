import React, {useState,useEffect} from 'react';
import Sidebar from '../parts/sidebar'
import {
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCheckbox,
    MDBCol,
    MDBBtn,
    MDBSpinner
} from 'mdb-react-ui-kit';
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalBody,
} from 'mdb-react-ui-kit';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FileUploader } from "react-drag-drop-files";
import Cookies from 'js-cookie';

const Dashboard = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [autofile, setAutoFile] = useState(null);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [mycontrollers,setMycontrollers]=useState([]);
  const [filename,setFilename]=useState("");
  const fileTypes = ["bin"];

  const [makes,setMakes]=useState([]);
  const [ecu,setECU]=useState([]);
  const [scripts,setScripts]=useState([]);

  const [make,setMake]=useState("");
  const [sendMake,setSendMake]=useState("");
  const [selectEcu,setSelectedEcu]=useState("");

  const [reqMake,setReqmake]=useState("");
  const [reqEcu,setReqEcu]=useState("");
  const [file,setFile]=useState(null);
  const [Id,setId]=useState("");
  const [confirmSubmit,setConfirmSubmit]=useState(false);
  const [comments,setComments]=useState("");
  const [submit,setSubmit]=useState(false);
  const [fuel,setFuel]=useState("");
  const [filesubmit,setFileSubmit]=useState(false);

  const [possible,setPossible]=useState([]);
  const [solutions,setSolutions]=useState([]);
  const [reqSolutions,setReqSolutions]=useState([]);
  const [selectedSolutions, setSelectedSolutions] = useState([]);
  const [selectedFile,setSelectedFile]=useState([]);
  const [downloadSubmit,setDownloadsubmit]=useState(false);
  const [binFile,setBinFile]=useState("");
  const [show,setShow]=useState(false);

  const handleChange = (file) => {
    const isValidFileType = fileTypes.some((type) =>
      file.name.toLowerCase().endsWith(`.${type}`)
    );

    if (isValidFileType) {
      setAutoFile(file);
    } else {
      console.log("Invalid file type. Please upload a BIN file.");
    }
  };

  const handleReqChange = (file) => {
    const isValidFileType = fileTypes.some((type) =>
      file.name.toLowerCase().endsWith(`.${type}`)
    );

    if (isValidFileType) {
      setFile(file);
    } else {
      console.log("Invalid file type. Please upload a BIN file.");
    }
  };

  useEffect(()=>{
    checker();
    getDetails();
    document.body.style.backgroundColor="#1a2229";
  },[])

  const getDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getSolutions`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setSolutions(responseData.data);
    } catch (error) {
      console.error('Error:', error.message);
    }
};

  async function checker() {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Admin/getCars`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const responses = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Admin/getControllers`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );

      const responseScripts = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/getScripts`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      const data = await response.json();
      const datas = await responses.json();
      const datascript = await responseScripts.json();
      const sortedMakes = data.data.slice().sort((a, b) => {
        const nameA = a.carname.toLowerCase();
        const nameB = b.carname.toLowerCase();
      
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      
      setMakes(sortedMakes);

      const sortedControllers = datas.data.slice().sort((a, b) => {
        const nameA = a.controllername.toLowerCase();
        const nameB = b.controllername.toLowerCase();
      
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      
      setECU(sortedControllers);
      setScripts(datascript.data);
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  const handleSelectedEcu=(event)=>{
    setSelectedEcu(event.target.value);
  }

  const handleReqEcu=(event)=>{
    setReqEcu(event.target.value);
  }

  const handleAutomatisation = async (e) => {
    e.preventDefault();
    setFileSubmit(true);

    if (make === '' || selectEcu === '') {
      showErrorMessage();
      setFileSubmit(false);
    } else {
      let isFound = false;
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].car === sendMake && scripts[i].controller === selectEcu) {
          isFound = true;
        }
      }

      if (isFound) {
        const formData = new FormData();
        formData.append('car', sendMake);
        formData.append('controller', selectEcu);
        formData.append('binFile', autofile);
        formData.append('fuel', fuel);

        try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/Dealer/automatisation`,
            formData,
            {
            headers: {
                'Content-Type': 'multipart/form-data',
                'api-key': process.env.REACT_APP_API_KEY,
            },
            }
        );

        const data = response.data;
        console.log(data);
        if(data.solutions.every(solutionname => solutionname.solution === 'notfound')){
            setFileSubmit(false);
            setBasicModal(true);
        }
        else{
            setPossible(data.solutions);
            setBinFile(data.binFile);
            setFilename(data.filename);
            setFileSubmit(false);
            setShow(true);
        }
        } catch (error) {
        console.error('Error:', error);
        setFileSubmit(false);
        }
      } else {
        setFileSubmit(false);
        setBasicModal(true);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    if((make=="" && reqMake=="") || (selectEcu=="" && reqEcu=="")){
        showErrorMessage();
        setSubmit(false);
    }
    else{
        const form = e.target;
        const formData = new FormData();
        formData.append('make', sendMake);
        formData.append('ecu', selectEcu);
        formData.append('file', autofile);
        formData.append('comments', comments);
        formData.append('solution', selectedSolutions);
        formData.append('userId',Cookies.get("userId"));

        try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Dealer/requests`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            "api-key": process.env.REACT_APP_API_KEY,
            },
        });
        const data = response.data;
        if(data.message=="added"){
            setId(data.id);
            form.reset();
            setSubmit(false);
            setBasicModal(false);
            setOpenRequestModal(true);
        }
        } catch (error) {
        console.error('Error:', error.message);
        setSubmit(false);
        }
    }
  };

  async function handleDownload(e) {
    e.preventDefault();
      setDownloadsubmit(true);
      const form = e.target;
      const formData = new FormData();
  
      if (selectedFile.length === 0) {
        showErrorMessageSolution();
        setDownloadsubmit(false);
      } else {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/Dealer/downloadfile?file=${selectedFile}&filename=${filename}&binfile=${binFile}&car=${sendMake}&controller=${selectEcu}&userid=${Cookies.get("userId")}&solution=${JSON.stringify(selectedSolutions)}`,formData,
            {
              headers: {
                'Content-Type': 'application/json',
                'api-key': process.env.REACT_APP_API_KEY,
              },
            }
          );
          const data = response.data;
  
          if (data.message === "limit") {
            setDownloadsubmit(false);
            showLimitError();
          } else if (data.message === "lowcredits") {
            setDownloadsubmit(false);
            showLowCredits();
          } 
          else{
            await downloadFileFromBackend(`${process.env.REACT_APP_BASE_URL}/solutions/${data.file}`, data.file);
            setConfirmSubmit(false);
            setDownloadsubmit(false);
          }
        } catch (error) {
          console.error('Error:', error.message);
          setDownloadsubmit(false);
        }
    }
  }
  
  function downloadFileFromBackend(filePath, filename) {
    try {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = filePath;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }

  const showErrorMessage = () => {
    toast.error('Give complete details', {
      position: 'top-center',
    });
  }

  const showErrorMessageSolution = () => {
    toast.error('Choose Solution Type', {
      position: 'top-center',
    });
  }

  const showLimitError = () => {
    toast.error('Your Daily solution limit reached', {
      position: 'top-center',
    });
  }

  const showLowCredits = () => {
    toast.error('You have not enough credits for this solution', {
      position: 'top-center',
    });
  }


  const handleCheckboxChange = (solutionName,file) => {
    const isSolutionSelected = selectedSolutions.includes(solutionName);
    
    if (isSolutionSelected) {
      setSelectedSolutions((prevSelected) => prevSelected.filter((selected) => selected !== solutionName));
      setSelectedFile((prevSelected) => prevSelected.filter((selected) => selected !== file));
    } else {
      setSelectedSolutions((prevSelected) => [...prevSelected, solutionName]);
      setSelectedFile((prevSelected) => [...prevSelected, file]);
    }
  };


  return (
    <div style={{ position: 'relative', width: '100%'}}>
      <ToastContainer />
      <Sidebar />

    <form onSubmit={handleAutomatisation}>
      <div className='main-content'>
        <h3 style={{color:"white",marginLeft:"2%",fontFamily:"bahnschrift",marginTop:"2%"}}>Choose File</h3>
        <MDBCard className='text-center' style={{margin:"30px",backgroundColor:"#2d353c"}}>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol md="4">
                        <Form>
                            <label htmlFor="mySelect" style={{ color: 'black', fontSize: '14px', display: 'block', textAlign: 'left' }}>
                                Car Make
                            </label>
                            <br />
                            <Form.Group controlId="mySelect" style={{marginTop:"-15px"}}>
                            <Form.Select
                                style={{ backgroundColor: '#2d353c', border:"1px solid black", color: '#acb4bd' }}
                                value={make}
                                id="usermake"
                                onChange={(event) => {
                                    const selectedValue = event.target.value;
                                    const selectedText = event.target.options[event.target.selectedIndex].text;
                                    setMake(selectedValue); 
                                    setSendMake(selectedText);
                                    console.log('Selected Make:', selectedText);
                                    setMycontrollers(ecu.filter((item) => item.makeId == selectedValue));
                                }}
                            >
                                <option value="">Select Make</option>
                                {makes.map((item, index) => (
                                    <option value={item.Id} key={item.Id}>
                                        {item.carname}
                                    </option>
                                ))}
                            </Form.Select>
                            </Form.Group>
                        </Form>
                    </MDBCol>
                    <MDBCol md="4">
                        <Form>
                            <label htmlFor="mySelect" style={{ color: 'white', fontSize: '14px', display: 'block', textAlign: 'left' }}>
                                Controllers
                            </label>
                            <br />
                            <Form.Group controlId="mySelect" style={{marginTop:"-15px"}}>
                            <Form.Select style={{backgroundColor:'#2d353c',border:"1px solid black",color:"#acb4bd"}} value={selectEcu} onChange={handleSelectedEcu}>
                                <option value="">Controllers</option>
                                {mycontrollers.map((item,index)=>(
                                    <option value={item.controllername}>{item.controllername}</option>
                                ))}
                            </Form.Select>
                            </Form.Group>
                        </Form>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol md={12}>
                        <div style={{marginTop:"20px"}}>
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={false}/>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
      </div>
      <center><MDBBtn style={{backgroundColor:"white",width:"150px",color:"black",position:"absolute",right:"28px"}}>
        {filesubmit?(
            <MDBSpinner></MDBSpinner>
        ):(
            <span>Check</span>
        )}
        </MDBBtn></center>
        </form>

        {show?(
            <div>
                <div className='main-content' style={{marginTop:"80px"}}>
                <h3 style={{color:"white",marginLeft:"2%",fontFamily:"bahnschrift"}}>Choose Your Solution </h3>
                <MDBRow style={{margin:"20px"}}>
                <MDBCol md={4}>
                    <p style={{ color: 'white', fontFamily: 'bahnschrift', marginLeft: '2%' }}>Deactivations</p>
                    <div style={{ marginLeft: '2%' }}>
                    {solutions
                        .filter((item) => item.category === 'Deactivation' && possible.some((p) => p.solution === item.solutionname))
                        .map((solution, index) => {
                        const label = 'Automatic'; 

                        return (
                            <div key={index}>
                            <MDBCheckbox
                                name="deactivationGroup"
                                value={solution.solutionname}
                                id={solution.solutionname}
                                checked={selectedSolutions.includes(solution.solutionname)}
                                onChange={() =>{
                                    const possibleObject = possible.find((item) => item.solution === solution.solutionname);
                                    handleCheckboxChange(solution.solutionname,possibleObject.fileName); 
                                }}
                                label={
                                <span style={{ color: 'white' }}>
                                    {solution.solutionname}{' '}
                                    <span style={{ backgroundColor: 'green', borderRadius: '5px', padding: '5px', fontSize: '12px', marginLeft: '3px' }}>
                                    {label}
                                    </span>
                                </span>
                                }
                            />
                            </div>
                        );
                        })}
                    </div>
                </MDBCol>
                <MDBCol md={4}>
                    <p style={{ color: 'white', fontFamily: 'bahnschrift', marginLeft: '2%' }}>Tuning</p>
                    <div style={{ marginLeft: '2%' }}>
                    {solutions
                        .filter((item) => item.category === 'Tuning' && possible.some((p) => p.solution === item.solutionname))
                        .map((solution, index) => {
                        const label = 'Automatic'; // Since we are only including automatic solutions in the filter

                        return (
                            <div key={index}>
                            <MDBCheckbox
                                name="deactivationGroup"
                                value={solution.solutionname}
                                id={solution.solutionname}
                                checked={selectedSolutions.includes(solution.solutionname)}
                                onChange={() =>{
                                    const possibleObject = possible.find((item) => item.solution === solution.solutionname);
                                    handleCheckboxChange(solution.solutionname,possibleObject.fileName); 
                                }}
                                label={
                                <span style={{ color: 'white' }}>
                                    {solution.solutionname}{' '}
                                    <span style={{ backgroundColor: 'green', borderRadius: '5px', padding: '5px', fontSize: '12px', marginLeft: '3px' }}>
                                    {label}
                                    </span>
                                </span>
                                }
                            />
                            </div>
                        );
                        })}
                    </div>
                </MDBCol>
                <MDBCol md={4}>
                    <p style={{ color: 'white', fontFamily: 'bahnschrift', marginLeft: '2%' }}>Tuning</p>
                    <div style={{ marginLeft: '2%' }}>
                    {solutions
                        .filter((item) => item.category === 'Miscle' && possible.some((p) => p.solution === item.solutionname))
                        .map((solution, index) => {
                        const label = 'Automatic'; 

                        return (
                            <div key={index}>
                            <MDBCheckbox
                                name="deactivationGroup"
                                value={solution.solutionname}
                                id={solution.solutionname}
                                checked={selectedSolutions.includes(solution.solutionname)}
                                onChange={() =>{
                                    const possibleObject = possible.find((item) => item.solution === solution.solutionname);
                                    handleCheckboxChange(solution.solutionname,possibleObject.fileName); 
                                }}
                                label={
                                <span style={{ color: 'white' }}>
                                    {solution.solutionname}{' '}
                                    <span style={{ backgroundColor: 'green', borderRadius: '5px', padding: '5px', fontSize: '12px', marginLeft: '3px' }}>
                                    {label}
                                    </span>
                                </span>
                                }
                            />
                            </div>
                        );
                        })}
                    </div>
                </MDBCol>
            </MDBRow>
            </div>
            <div className='main-content'>
                <h3 style={{color:"white",marginLeft:"2%",fontFamily:"bahnschrift"}}>Get Your file</h3>
                <MDBRow style={{margin:"20px"}}>
                    <MDBCol md={3}>
                        <MDBBtn style={{backgroundColor:"#c9273f",width:"100%",marginTop:"5px"}} onClick={()=>{setConfirmSubmit(true)}}>
                            Download File
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </div>
          </div>
        ):(
            ""
        )}

      <MDBModal show={basicModal} onHide={() => setBasicModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="lg">
            <MDBModalContent style={{ backgroundColor: '#1a2229', color: 'black' }}>
            <MDBModalBody>
                <center><h3 style={{color:"white",fontFamily:"bahnschrift"}}>OPEN NEW REQUEST</h3></center>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <MDBRow style={{ marginTop: "25px" }}>
                    <MDBCol md={4}>
                    <p style={{color:'white', fontFamily:"bahnschrift", marginLeft:'2%'}}>Deactivations</p>
                        <div style={{marginLeft:"2%"}}>
                        {solutions.filter((item) => item.category === "Deactivation").map((solution, index) => {
                                const isPossible = possible.some((item) => item.solution === solution.solutionname);
                                const label = isPossible ? "Automatic" : "Not Automatic";

                                return (
                                    <div key={index}>
                                        <MDBCheckbox
                                            name="deactivationGroup"
                                            value={solution.solutionname}
                                            id={solution.solutionname}
                                            checked={selectedSolutions.includes(solution.solutionname)}
                                            onChange={() => handleCheckboxChange(solution.solutionname)}
                                            label={
                                                <span style={{ color: 'white' }}>
                                                    {solution.solutionname}
                                                </span>
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </MDBCol>
                    <MDBCol md={4}>
                        <p style={{color:'white',fontFamily:"bahnschrift",marginLeft:'2%'}}>Tuning</p>
                        <div style={{marginLeft:"2%"}}>
                            {solutions.filter((item)=>item.category=="Tuning").map((solution, index) => {
                                const isPossible = possible.some((item) => item.solution === solution.solutionname);
                                const label = isPossible ? "Automatic" : "Not Automatic";

                                return (
                                    <div key={index}>
                                    <MDBCheckbox
                                        name="flexCheck"
                                        value={solution.solutionname}
                                        id={solution.solutionname}
                                        checked={selectedSolutions.includes(solution.solutionname)}
                                        onChange={() => handleCheckboxChange(solution.solutionname)}
                                        label={
                                        <span style={{ color: 'white' }}>
                                            {solution.solutionname}
                                        </span>
                                        }
                                    />
                                    </div>
                                );
                            })}
                        </div>
                    </MDBCol>
                    <MDBCol md={4}>
                        <p style={{color:'white',fontFamily:"bahnschrift",marginLeft:'2%'}}>Miscellaneous</p>
                        <div style={{marginLeft:"2%"}}>
                            {solutions.filter((item)=>item.category=="Miscle").map((solution, index) => {
                                    const isPossible = possible.some((item) => item.solution === solution.solutionname);
                                    const label = isPossible ? "Automatic" : "Not Automatic";

                                    return (
                                        <div key={index}>
                                        <MDBCheckbox
                                            name="flexCheck"
                                            value={solution.solutionname}
                                            checked={selectedSolutions.includes(solution.solutionname)}
                                            onChange={() => handleCheckboxChange(solution.solutionname)}
                                            id={solution.solutionname}
                                            label={
                                            <span style={{ color: 'white' }}>
                                                {solution.solutionname}
                                            </span>
                                            }
                                        />
                                        </div>
                                    );
                                })}
                        </div>
                    </MDBCol>
                    </MDBRow>

                    <Form.Control
                        as="textarea"
                        placeholder="Your Comments"
                        rows={4}
                        size="lg"
                        id="card"
                        name="comments"
                        onChange={(event) => setComments(event.target.value)}
                        style={{
                            borderRadius: 0,
                            color: "black",
                            backgroundColor: "#1a2229",
                            borderRadius: "3px",
                            flex: 1,
                            marginTop: "7px"
                        }}
                    />
                    <center>
                        <MDBBtn style={{ backgroundColor: "white",color:"black",width:"100%", marginTop: "5px" }}>
                            {submit ? (
                                <MDBSpinner></MDBSpinner>
                            ) : (
                                <span>Open New Request</span>
                            )}
                        </MDBBtn>
                    </center>
                </form>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>

      <MDBModal show={openRequestModal} onHide={() => setOpenRequestModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="lg">
            <MDBModalContent style={{ backgroundColor: '#303030', color: 'white' }}>
            <MDBModalBody>
            <center><h3 style={{color:"white",fontFamily:"bahnschrift",marginTop:"15px"}}>OPEN NEW REQUEST</h3></center>
                
            <center>
                <div>
                    <p style={{marginTop:"30px"}}>Your request was opened successfully.</p>
                    <h3 style={{marginTop:"15px",marginBottom:"15px"}}>The request number is #{Id}</h3>
                    <p style={{marginBottom:"40px"}}>Our team will process your file and get in <br /> touch with you as soon as possible.</p>
                </div>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>

      <MDBModal show={confirmSubmit} onHide={() => setConfirmSubmit(false)} tabIndex='-1'>
        <MDBModalDialog centered size="lg">
            <MDBModalContent style={{ backgroundColor: '#303030', color: 'white' }}>
            <MDBModalBody>
            <center><h3 style={{color:"white",fontFamily:"bahnschrift",marginTop:"15px"}}>CONFIRMATION</h3></center>
                
            <center>
                <div>
                    <p style={{marginTop:"30px"}}>you are aware not all solutions are legal. This software is intended for testing purposes only and should not be used on public roads. Please note that the checksum has not been corrected.</p>
                </div>
                <center>
                  <div style={{display:"flex",justifyContent:"center"}}>
                    <MDBBtn style={{backgroundColor:"#c9273f",color:"white",marginRight:"10px"}} onClick={handleDownload}>
                      {downloadSubmit?(
                        <MDBSpinner></MDBSpinner>
                      ):(
                        <span>Confirm</span>
                      )}
                    </MDBBtn>
                    <MDBBtn style={{backgroundColor:"white",color:"black"}} onClick={()=>{setConfirmSubmit(false)}}>Cancel</MDBBtn>
                  </div>
                </center>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    </div>
  );
};

export default Dashboard;
