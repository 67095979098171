import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='text-center text-lg-start text-muted' style={{marginTop:"5%"}}>
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Art Tuner
              </h6>
              <p>
              Art Tuner is a cutting-edge server optimization tool meticulously engineered to enhance performance and streamline operations for businesses of all scales.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Soltions</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Stage 1
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Stage 2
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Tuning
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  DPF
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Home
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Login
                </a>
              </p>
              <p>
                <a href='/faqs' className='text-reset'>
                  Faqs
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Help
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Riyadh,abi alhassan ibn alathair road,SA
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                naif.snap@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +966553877552
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
           art-tuner.com
        </a>
      </div>
    </MDBFooter>
  );
}