import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import {Link} from 'react-router-dom';

export default function Navbar() {
  const [openBasic, setOpenBasic] = useState(false);

  return (
    <MDBNavbar expand='lg' style={{backgroundColor:"#2d353c"}}>
    <MDBContainer fluid>
        <MDBNavbarBrand href='#'>
        <img src="../Assets/logo.png" alt="" style={{width:"200px"}}/>
        </MDBNavbarBrand>

        <MDBNavbarToggler
        aria-controls='navbarSupportedContent'
        aria-expanded='false'
        aria-label='Toggle navigation'
        onClick={() => setOpenBasic(!openBasic)}
        >
        <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openBasic}>
        <MDBNavbarNav className='mx-auto'>
            {/* <MDBNavbarItem style={{ display: "inline-block" }}>
            <MDBNavbarLink active aria-current='page' href='#'>
                Home
            </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem style={{ display: "inline-block" }}>
            <MDBNavbarLink href='#'>Link</MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem style={{ display: "inline-block" }}>
            <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                Dropdown
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                <MDBDropdownItem link>Action</MDBDropdownItem>
                <MDBDropdownItem link>Another action</MDBDropdownItem>
                <MDBDropdownItem link>Something else here</MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem style={{ display: "inline-block" }}>
            <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                Disabled
            </MDBNavbarLink>
            </MDBNavbarItem> */}
        </MDBNavbarNav>

        <form className='d-flex input-group w-auto'>
           <Link to="/login"><MDBBtn style={{backgroundColor:"#ffffff",color:"#9a1810"}}>Login</MDBBtn></Link>
        </form>
        </MDBCollapse>
    </MDBContainer>
    </MDBNavbar>
  );
}