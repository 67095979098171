import React,{useEffect} from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

const col2Style = {
  height: '100%',
  overflow: 'auto',
  padding: '0',
  position: 'relative',
};

const textStyle = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  padding: '20px',
  boxSizing: 'border-box',
  color: '#fff',
};

export default function Register() {
  const [submit,setSubmit]=useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryList = response.data.map(country => ({
          name: country.name.common,
          code: country.cca2,
        }));
        setCountries(countryList);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, [])

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const showErrorMessage = () => {
    toast.error('Check the reCaptcha', {
      position: 'top-center',
    });
  }

  const showEmailMessage = () => {
    toast.error('Email already exists', {
      position: 'top-center',
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    const form = e.target;
    const formData = new FormData(form);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Dealer/register`, formData, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      if(responseData.message=="sent"){
        Cookies.set('email', responseData.email, { expires: 1 });
        Cookies.set('auth', responseData.code, { expires: 1 });
        window.location.href=`/verification`;
        form.reset();
      }
      else if(responseData.message=="already"){
        showEmailMessage();
      }
      setSubmit(false);
    } catch (error) {
      console.error('Error:', error.message);
      setSubmit(false);
    }
  };

  return (
    <MDBRow style={{ margin: 0, width: '100%', overflow: 'hidden',backgroundColor:"white" }}>
      <ToastContainer />
      <MDBCol md='8' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
        <img
          src="../Assets/car4.jpg"
          alt=""
          style={{ width: "100%", height: "100%" }} // Adjusted styles
        />
      </MDBCol>
      <MDBCol md='4' style={{...col2Style, display: 'flex', justifyContent: 'center', alignItems: 'center',backgroundColor:"#00020a"}}>
        <div style={{ marginLeft: '20px', marginTop: '10%', padding: '30px',width:"500px" }}>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Sign Up</h3>
          <form onSubmit={handleSubmit} id="registerform">
          <MDBRow>
            <p style={{fontSize:"15px",marginBottom:"5px",color:"white"}}>Name <span style={{color:"red"}}>*</span></p>
            <MDBCol md='6' sm={12}>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px" }}
                    placeholder="First Name"
                    name='firstname'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>

            <MDBCol md='6' sm={12}>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px"}}
                    placeholder="Last Name"
                    name='lastname'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{marginTop:"5px"}}>
            <p style={{fontSize:"15px",marginBottom:"5px",color:"white"}}>Email <span style={{color:"red"}}>*</span></p>
            <MDBCol md='12'>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px" }}
                    placeholder="Email address"
                    name='email'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{marginTop:"5px"}}>
            <p style={{fontSize:"15px",marginBottom:"5px",color:"white"}}>Phone <span style={{color:"red"}}>*</span></p>
            <MDBCol md='12'>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px" }}
                    placeholder="Phone"
                    name='phone'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{marginTop:"5px"}}>
            <p style={{fontSize:"15px",marginBottom:"5px",color:"white"}}>Location <span style={{color:"red"}}>*</span></p>
            <MDBCol md='6' sm={12}>
               <select
                className="form-control"
                style={{ backgroundColor: '#00020a', marginTop: '5px',color:"#95a9be" }}
                value={selectedCountry}
                onChange={handleCountryChange}
                name='country'
                required
                >
                {countries.map(country => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </MDBCol>

            <MDBCol md='6' sm={12}>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px"}}
                    placeholder="City"
                    name='city'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{marginTop:"5px"}}>
            <p style={{fontSize:"15px",marginBottom:"5px",color:"white"}}>Address <span style={{color:"red"}}>*</span></p>
            <MDBCol md='6' sm={12}>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px"}}
                    placeholder="Address"
                    name='address'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>

            <MDBCol md='6' sm={12}>
                <input
                    type="text"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px"}}
                    placeholder="Post Code"
                    name='postcode'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>
          </MDBRow>

          <MDBRow style={{marginTop:"5px"}}>
            <p style={{fontSize:"15px",marginBottom:"5px",color:"white"}}>Password <span style={{color:"red"}}>*</span></p>
            <MDBCol md='12'>
                <input
                    type="password"
                    className="form-control"
                    style={{ backgroundColor: '#00020a',marginTop:"5px"}}
                    placeholder="Password"
                    name='password'
                    required
                />
                <style>
                    {`
                    .form-control::placeholder {
                        color: #95a9be;
                        font-size:14px;
                    }
                    `}
                </style>
            </MDBCol>
          </MDBRow>

          <MDBBtn style={{ marginTop: '30px', width: '100%', background: "linear-gradient(to right, #e23b09, #8a020c)", color: 'white' }}>
            {submit?(
              <MDBSpinner></MDBSpinner> 
            ):(
              <span>Sign Up</span>
            )}
          </MDBBtn>
          </form>

          <div className='links'>
            <p style={{ color: '#adb5b3', marginTop: '15px', fontSize: '15px' }}>
              Already have an account? Click <Link to='/' style={{ color: 'white', fontSize: '15px' }}>here</Link> to Login.
            </p>
          </div>

          <div style={{  left: '0', right: '0', textAlign: 'center',marginTop:"25px" }}>
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>© All Right Reserved {new Date().getFullYear()}</p>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
