import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

const textStyle = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  padding: '20px',
  boxSizing: 'border-box',
  color: '#fff',
};

export default function Login() {
  const [submit,setSubmit]=useState(false);

  const showErrorMessage = () => {
    toast.error('Invalid login', {
      position: 'top-center',
    });
  }

  const showNotVerified = () => {
    toast.error('Your Account is not verified', {
      position: 'top-center',
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
      setSubmit(true);
      
      const data={
          email:document.getElementById("email").value,
          password:document.getElementById("password").value,
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Dealer/login`, data, {
          headers: {
            'Content-Type': 'application/json',
            "api-key": process.env.REACT_APP_API_KEY,
          },
        });
        const responseData = response.data;
        if(responseData.message=="success"){
          Cookies.set('email', responseData.email, { expires: 1 });
          Cookies.set('name', responseData.name, { expires: 1 });
          Cookies.set('userId', responseData.userid, { expires: 1 });
          Cookies.set('token', responseData.token, { expires: 1 });
          window.location.href=`/dashboard`;
        }
        else if(responseData.message=="invalid"){
          showErrorMessage();
        }
        else if(responseData.message=="unverified"){
          showNotVerified();
        }
        else if(responseData.message=="invalid"){
          showErrorMessage();
        }
        setSubmit(false);
      } catch (error) {
        console.error('Error:', error.message);
        setSubmit(false);
      }
  };

  return (
    <MDBRow style={{ margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: 'white' }}>
  <ToastContainer />
  <MDBCol md='8' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
    <img
      src="../Assets/car4.jpg"
      alt=""
      style={{ width: "100%", height: "100%" }} // Adjusted styles
    />
  </MDBCol>
  <MDBCol md='4' style={{ ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#00020a", padding: 0 }}>
    <div style={{ marginLeft: '20px', padding: '20px', width: "400px" }}>
      <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>User Login</h3>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <label htmlFor="" style={{ color: "white" }}>Email Address</label>
          <MDBInput id='email' name='email' type='text' style={{ backgroundColor: '#00020a', color: "white" }} />
        </div>
        <div style={{ marginTop: '20px', width: '100%' }}>
          <label htmlFor="" style={{ color: "white" }}>Password</label>
          <MDBInput id='password' name='password' type='password' style={{ backgroundColor: '#00020a', color: "white" }} />
        </div>
        <MDBBtn style={{ marginTop: '30px', width: '100%', background: "linear-gradient(to right, #e23b09, #8a020c)", color: 'white' }}>
          {submit ? (
            <MDBSpinner></MDBSpinner>
          ) : (
            <span>Login</span>
          )}
        </MDBBtn>
      </form>

      <div className='links'>
        <p style={{ color: '#adb5b3', marginTop: '15px', fontSize: '15px' }}>
          Don't have an account? Click <Link to='/register' style={{ color: 'white', fontSize: '15px' }}>here</Link> to register.
        </p>
      </div>

      <div style={{ position: 'absolute', bottom: '20px', left: '0', right: '0', textAlign: 'center' }}>
        <hr style={{ color: '#adb5b3' }} />
        <p style={{ color: 'white', fontSize: '15px' }}>© All Right Reserved {new Date().getFullYear()}</p>
      </div>
    </div>
  </MDBCol>
</MDBRow>

  );
}
