import React, {useEffect, useState} from 'react';
import Sidebar from '../parts/sidebar'
import { MDBSpinner, MDBTable, MDBTableBody } from 'mdb-react-ui-kit';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput
} from 'mdb-react-ui-kit';
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Subscriptions = () => {
  const [submit,setSubmit]=useState(false);
  const [creditSubmit,setCreditSubmit]=useState(false);
  const [creditsModal, setCreditsModal] = useState(false);
  const [value, setValue] = useState(1);
  const [price, setPrice] = useState();
  const [pricewot, setPricewot] = useState();

  useEffect(()=>{
    document.body.style.backgroundColor="#1a2229";
    getPricing();
  },[])

  const handleDecrement = () => {
    if(value-1>0){
      setValue(value - 1);
    }
  };

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const getPricing = async (e) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/Admin/getPricing`, {
        headers: {
          'Content-Type': 'application/json',
          "api-key": process.env.REACT_APP_API_KEY,
        },
      });
      const responseData = response.data;
      setPricewot(responseData.data[0].pricewot);
      setPrice(responseData.data[0].pricewt);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  async function subscribe() {
    setSubmit(true);
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/annualsubscription?userId=${Cookies.get("userId")}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      setSubmit(false);
      const data = await response.json();
      if(data.message=="already"){
        showErrorMessage();
      }
      else{
        window.location.href=data.sessionUrl;
      }
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  async function buyCredits() {
    setCreditSubmit(true);
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/Dealer/buyCredits?userId=${Cookies.get("userId")}&credits=${value}`,
          {
              method: "GET",
              headers: {
                  "api-key": process.env.REACT_APP_API_KEY,
              },
          }
      );
  
      setCreditSubmit(false);
      const data = await response.json();
      window.location.href=data.sessionUrl;
    } catch (error) {
        console.error("Error:", error);
    }    
  }

  const showErrorMessage = () => {
    toast.error('You Already have a subscription', {
      position: 'top-center',
    });
  }

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <ToastContainer />
      <Sidebar />

      <div className='main-content'>
        <div style={{marginTop:"2%",marginLeft:"3%"}}>
        <center>
        <div>
        <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{margin:"30px"}}>
            <MDBCol>
                <MDBCard className='h-100' style={{backgroundColor:'#2d353c',borderRadius:"0",color:"black"}}>
                <MDBCardBody style={{color:"white"}}>
                    <MDBCardTitle>Buy Credits</MDBCardTitle>
                    <MDBCardText>
                     {price}$ Per Credit with tax
                     <br />
                     3 Credits for Solution
                     <br />
                     7 Credits for Tuning
                     <br />
                     <MDBBtn style={{marginTop:"25px",backgroundColor:"white",color:"black"}} onClick={()=>{setCreditsModal(true)}}>Get Credits</MDBBtn>
                    </MDBCardText>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol>
                <MDBCard className='h-100' style={{backgroundColor:"#2d353c",borderRadius:"0",color:"black"}}>
                <MDBCardBody style={{color:"white"}}>
                    <MDBCardTitle>Annual Subscription</MDBCardTitle>
                    <MDBCardText>
                     1200$ Annually
                     <br />
                     10 Solutions Free Per Day
                     <br />
                     Except Stage 1, Stage 1 Cost 5 Credits
                     <br />
                     <MDBBtn style={{marginTop:"25px",backgroundColor:"white",color:"black"}} onClick={subscribe}>{
                        submit?(
                            <MDBSpinner></MDBSpinner>
                        ):(
                            <span>Get Subscription</span>
                        )
                     }</MDBBtn>
                    </MDBCardText>
                </MDBCardBody>
                </MDBCard>
            </MDBCol>
            </MDBRow>
            </div>
        </center>
        </div>
      </div>

      <MDBModal show={creditsModal} onHide={() => setCreditsModal(false)} tabIndex='-1'>
        <MDBModalDialog centered size="md">
            <MDBModalContent style={{ backgroundColor: '#303030', color: 'white' }}>
            <MDBModalBody>
            <center><h3 style={{color:"white",fontFamily:"bahnschrift",marginTop:"15px"}}>Get Credits</h3></center>
                
            <center>
                <p>Price: {price*value}€</p>
                  <div style={{display:"flex"}}>
                  <MDBBtn onClick={handleDecrement} outline color='primary' style={{marginRight:"5px",backgroundColor:"white",color:"black"}}>
                    -
                  </MDBBtn>
                  <MDBInput id='form1' type='text' value={value} style={{width:"365px",color:"white"}}/>
                  <MDBBtn onClick={handleIncrement} outline color='primary' style={{marginLeft:'5px',backgroundColor:"white",color:"black"}}>
                    +
                  </MDBBtn>
                </div>
                <MDBBtn style={{marginTop:"25px",backgroundColor:"white",color:"black"}} onClick={buyCredits}>
                  {creditSubmit?(
                    <MDBSpinner></MDBSpinner>
                  ):(
                    <span>Proceed to Payment</span>
                  )}
                </MDBBtn>
            </center>
            </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    </div>
  );
};

export default Subscriptions;
