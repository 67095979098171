import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import Faq from "react-faq-component";

const data = {
    title: "FAQ",
    rows: [
        {
            title: "What is Art Tuner?",
            content: `Art Tuner is a cutting-edge server optimization tool designed to enhance performance and streamline operations for businesses of all scales.`,
        },
        {
            title: "How does Art Tuner work?",
            content:
                "Art Tuner leverages advanced algorithms, machine learning, and predictive analytics to intelligently adjust server configurations in real-time based on workload demands. This ensures optimal resource allocation and minimizes downtime.",
        },
        {
            title: "What benefits does Art Tuner offer?",
            content: `Art Tuner revolutionizes server management by offering unparalleled efficiency and reliability. It boosts productivity, optimizes costs by maximizing hardware utilization, and delivers seamless performance and scalability. `,
        },
        {
            title: "What features does Art Tuner offer?",
            content: "Art Tuner provides an intuitive interface for easy management. Its adaptive capabilities include fine-tuning memory allocation, optimizing CPU usage, and managing storage resources.",
        },
    ],
};

const styles = {
    bgColor: '#1a2229',
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: 'grey',
    arrowColor: "red",
};

const config = {
    animate: true,
    arrowIcon: "V",
    tabFocus: true
};

export default function Faqs(){
    useEffect(()=>{
        document.body.style.backgroundColor="#1a2229";
    })
    return (
        <div>
            <Navbar/>
            <div style={{margin:"50px"}}>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>
        </div>
    );
}