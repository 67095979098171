import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBBtn,MDBSpinner } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Cookies from 'js-cookie';
import { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const colStyle = {
  height: '100%',
  overflow: 'hidden',
  padding: '0',
  position: 'relative',
};

const textStyle = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  padding: '20px',
  boxSizing: 'border-box',
  color: '#fff',
};

export default function Verification() {
    const [submit,setSubmit]=useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
          setSubmit(true);
          
          const data={
            email:Cookies.get("email"),
            auth:Cookies.get("auth"),
            code:document.getElementById("code").value,
          }

          try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Dealer/verify`, data, {
              headers: {
                'Content-Type': 'application/json',
                "api-key": process.env.REACT_APP_API_KEY,
              },
            });
            const responseData = response.data;
            if(responseData.message=="verified"){
                showSuccessMessage();
            }
            else if(responseData.message=="unverified"){
                showErrorMessage();
            }
            setSubmit(false);
          } catch (error) {
            console.error('Error:', error.message);
            setSubmit(false);
          }
      };

      const showErrorMessage = () => {
        toast.error('Invalid Verification Code', {
          position: 'top-center',
        });
      }

      const showSuccessMessage = () => {
        toast.success('Email verified', {
          position: 'top-center',
          onClose: () => {
            window.location.href="/";
          },
        });
      }
    
  return (
    <MDBRow style={{ margin: 0, width: '100%', height: '100vh', overflow: 'hidden', backgroundColor: '#1A2229' }}>
      <ToastContainer />
      <MDBCol md='12' style={{ ...colStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ marginLeft: '20px', padding: '20px', width: '400px'}}>
          <center>
            <img src="./Assets/logo.png" alt="" style={{ width: '300px' }} />
          </center>
          <h3 style={{ color: 'white', fontWeight: '300', marginTop: '30px' }}>Account Verification</h3>
          <p style={{ color: '#405e77' }}>Verify your account</p>
          <form onSubmit={handleSubmit}>
            <div style={{ marginTop: '20px' }}>
              <MDBInput label='Verification Code' id='code' name='code' type='text' style={{ backgroundColor: '#6c757d' }} />
            </div>
            <MDBBtn style={{ marginTop: '30px', width: '100%', backgroundColor: '#c32941', color: 'white' }}>
              {submit ? (
                <MDBSpinner></MDBSpinner>
              ) : (
                <span>Verify</span>
              )}
            </MDBBtn>
          </form>

          <div style={{ position: 'absolute', bottom: '20px', left: '0', right: '0', textAlign: 'center' }}>
            <hr style={{ color: '#adb5b3' }} />
            <p style={{ color: '#adb5b3', fontSize: '15px' }}>© Layton Remaps All Right Reserved {new Date().getFullYear()}</p>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  );
}
